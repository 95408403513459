
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialPadComponent } from './dial-pad/dial-pad.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PhoneNumberTestComponent } from './phone-number-test/phone-number-test.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';

import { NoteDialogComponent } from './note-dialog/note-dialog.component';
import { TwilioService } from './twilio.service';
import { PreviousTestsDialogComponent } from './previous-tests-dialog/previous-tests-dialog.component';
import { MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { PublicClientApplication } from '@azure/msal-browser';

@NgModule({
  declarations: [
    AppComponent,
    DialPadComponent,
    PhoneNumberTestComponent,
    NoteDialogComponent,
    PreviousTestsDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatTableModule,
    MsalModule.forRoot(
      new PublicClientApplication(environment.msal.clientConfig),
      environment.msal.guardConfig as MsalGuardConfiguration,
      environment.msal.interceptorConfig as MsalInterceptorConfiguration)
  ],
  providers: [TwilioService, {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
