import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Test } from '../test';
import { phoneRecordTest } from '../phoneRecordTest';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-previous-tests-dialog',
  templateUrl: './previous-tests-dialog.component.html',
  styleUrls: ['./previous-tests-dialog.component.scss']
})
export class PreviousTestsDialogComponent {
  displayedColumns: string[] = ['testStartDate', 'percentComplete'];

  constructor(private http: HttpClient,
    public dialogRef: MatDialogRef<PreviousTestsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Test[]) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectTest(test: Test): void {
    this.http.get(`${environment.api}GetTestRecords/${test.testId}`).subscribe(records  => {
      this.dialogRef.close(records);
    });
  }

}
