import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { phoneNumberTestRecord } from '../phoneRecordTest';

export interface DialogData {
  record: phoneNumberTestRecord;
}

@Component({
  selector: 'app-note-dialog',
  templateUrl: './note-dialog.component.html',
})
export class NoteDialogComponent implements OnInit {
  public recordNotes: string = "";
  constructor(
    public dialogRef: MatDialogRef<NoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    if (this.data && this.data.record && this.data.record.notes)
      this.recordNotes = this.data.record.notes;
  }

  onNoClick(): void {
    this.recordNotes = "";
    this.dialogRef.close();

  }

  onYesClick(): void {
    this.data.record.notes = this.recordNotes;
    this.recordNotes = "";
    this.dialogRef.close(this.data.record.notes);
  }

}
