import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DialPadComponent } from './dial-pad/dial-pad.component';
import { MsalGuard } from '@azure/msal-angular';


const routes: Routes = [
  { path: '', canActivate: [MsalGuard], component: DialPadComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
