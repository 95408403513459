<div class="phone-inputs">
  <div class="input-fields">
<mat-form-field appearance="fill">
  <mat-label>To:</mat-label>
  <input matInput readonly [(ngModel)]="currentTest.toFriendlyName">
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>From:</mat-label>
  <input matInput readonly [(ngModel)]="currentTest.fromFriendlyName">
</mat-form-field>
</div>
<div class="phone-buttons">
  <button mat-icon-button class="phone-call-button" (click)="makeCall()">
    <mat-icon>phone</mat-icon>
  </button>
  <button mat-icon-button class="phone-hangup-button" (click)="hangUp()">
    <mat-icon>phone_disabled</mat-icon>
  </button>
</div>
</div>
<div class="scrollable-content">
  <div *ngFor="let record of records" class="record">
    <div class="to-friendly-name">
      {{ record.toFriendlyName }} -- {{ record.toPhoneNumberFriendly }}
    </div>
    <div *ngFor="let fromRecord of record.fromPhoneNumbers" class="from-friendly-name">
      <div class="fromPhoneNumberName" (click)="setCurrentTest(record, fromRecord)" [ngClass]="{'active': selectedRecord === fromRecord}">
        {{ fromRecord.friendlyName }}
      </div>
      <button mat-icon-button color="primary" (click)="markSuccessful(fromRecord)">
        <mat-icon>check_circle</mat-icon>
      </button>
      <button mat-icon-button color="warn" (click)="markUnsuccessful(fromRecord)">
        <mat-icon>cancel</mat-icon>
      </button>
      <div *ngIf="fromRecord.isDirty" class="testResult">
        <mat-icon *ngIf="fromRecord.isSuccessful" class="isSuccess">check</mat-icon>
        <mat-icon *ngIf="!fromRecord.isSuccessful" class="isFailure">close</mat-icon>
      </div>
      <button mat-icon-button (click)="openNoteDialog(fromRecord)" [matTooltip]="getTooltipText(fromRecord)" matTooltipPosition="right" matTooltipClass="noteToolTip">
        <mat-icon>note_add</mat-icon>
      </button>
    </div>
  </div>
</div>
