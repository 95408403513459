import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoteDialogComponent } from '../note-dialog/note-dialog.component';
import { currentPhoneNumber, phoneNumberTestRecord, phoneRecordTest } from '../phoneRecordTest';
import { from } from 'rxjs';
import * as IMask from 'imask';
import { TwilioService } from '../twilio.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';

@Component({
  selector: 'app-phone-number-test',
  templateUrl: './phone-number-test.component.html',
  styleUrls: ['./phone-number-test.component.scss']
})
export class PhoneNumberTestComponent {
  @Input() records: phoneRecordTest[];

  public mask = IMask.createMask({
    mask: '(000) 000-0000',
  });

  public currentTest: currentPhoneNumber = new currentPhoneNumber();
  public selectedRecord: phoneNumberTestRecord = new phoneNumberTestRecord();

  constructor(private dialog: MatDialog, private twilioService: TwilioService, private http: HttpClient, private appService: AppService) { }

  saveRecord(record: phoneNumberTestRecord) {
    if (this.appService.user)
      record.callMadeBy = this.appService.user.homeAccountId;

    this.http.post(`${environment.api}SaveTestRecord`, record).subscribe(() => {
      console.log("Saved!");
    });
  }

  markSuccessful(record: phoneNumberTestRecord) {
    record.isDirty = true;
    record.isSuccessful = true;

    this.saveRecord(record);
  }

  markUnsuccessful(record: phoneNumberTestRecord) {
    record.isDirty = true;
    record.isSuccessful = false;

    this.saveRecord(record);
  }


  openNoteDialog(record: phoneNumberTestRecord) {
    const dialogRef = this.dialog.open(NoteDialogComponent, {
      width: '400px',
      data: { record: record }
    });

    dialogRef.afterClosed().subscribe(result => {
      record.notes = result;
      this.saveRecord(record);
    });
  }

  setCurrentTest(record: phoneRecordTest, fromRecord: phoneNumberTestRecord) {
    this.selectedRecord = fromRecord;
    this.currentTest.fromPhoneNumber = fromRecord.fromPhoneNumber;
    this.currentTest.fromFriendlyName = fromRecord.friendlyName;
    this.currentTest.toFriendlyName = record.toFriendlyName;
    this.currentTest.toPhoneNumber = record.toPhoneNumber;
  }

  makeCall() {
    if (this.selectedRecord) {
      this.selectedRecord.callDate = new Date();
      this.saveRecord(this.selectedRecord);
    }

    if (this.currentTest.fromFriendlyName === 'Unknown Caller')
      this.twilioService.makeCall('unknown', this.selectedRecord.toPhoneNumber);

    if (this.currentTest.fromFriendlyName === 'Medical Provider')
      this.twilioService.makeCall('provider', this.selectedRecord.toPhoneNumber);

    if (this.currentTest.fromFriendlyName === 'Insurance Adjuster/Vendor')
      this.twilioService.makeCall('vendor', this.selectedRecord.toPhoneNumber);

    if (this.currentTest.fromFriendlyName === 'Existing Client')
      this.twilioService.makeCall('client', this.selectedRecord.toPhoneNumber);
  }

  hangUp() {
    this.twilioService.hangUp();
  }

  public getTooltipText(fromRecord: phoneNumberTestRecord) {

    if (fromRecord && fromRecord.notes)
      return `${fromRecord.notes}`;

    return null;
  }
}
