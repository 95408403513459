export class phoneRecordTest
{
   toPhoneNumber: string;
   toFriendlyName: string;
   fromPhoneNumbers: phoneNumberTestRecord[];
   toPhoneNumberFriendly: string;
}

export class phoneNumberTestRecord
{
  resultId?: number;
  testId?: number;
  callDate?: Date;
  toPhoneNumber: string;
  fromPhoneNumber: string;
  friendlyName: string;
  isSuccessful?: boolean;
  notes?: string;
  callMadeBy?: string;
  isDirty?: boolean;
}

export class currentPhoneNumber{
  toPhoneNumber: string;
  toFriendlyName: string;
  fromPhoneNumber: string;
  fromFriendlyName: string;
}
