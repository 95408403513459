<h2 class="app-title">DC Law Phone Number Tester</h2>
<div class="app-container">
  <div class="container">
    <div class="test-decision-container">
    <div *ngIf="!testLoaded">
      <button mat-icon-button class="test-button start" (click)="startTest()" >
        Start New Test
      </button>
      <button mat-icon-button class="test-button continue" (click)="continueTest()">
        Continue Test
      </button>
    </div>
  </div>

    <div *ngIf="testLoaded" class="phone-record-tree">
      <app-phone-number-test [records]="phoneRecords"></app-phone-number-test>
    </div>
  </div>
</div>
