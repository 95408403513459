import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventType } from '@azure/msal-browser';
import { Subject, Observable } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject();

  constructor(private broadcastService: MsalBroadcastService,
              private appService: AppService,
              private router: Router) {}

  async ngOnInit(): Promise<void> {
    this.subscribeToLoginSuccess();
    this.subscribeToLoginFailure();
    await this.appService.init(this.router);
  }


  subscribeToLoginSuccess(): void {
    this.msalItem([EventType.LOGIN_SUCCESS]).subscribe(async () => await this.appService.init(this.router));
  }

  subscribeToLoginFailure(): void {
    this.msalItem([
      EventType.LOGIN_FAILURE,
      EventType.ACQUIRE_TOKEN_FAILURE,
      EventType.SSO_SILENT_FAILURE
    ]).subscribe(() => {
      this.appService.requestLogin();
    });
  }

  private msalItem(types: string[]): Observable<any> {
    return this.broadcastService.msalSubject$.pipe(
      takeUntil(this.destroyed$),
      filter(it => types.includes(it?.eventType))
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }
}
