// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { InteractionType } from "@azure/msal-browser";

export const environment = {
  production: false,
  api: "/api/",
  msal: {
    clientConfig: {
      auth: {
        clientId: '754db438-6784-41aa-995f-a7c6eccbaf0a',
        authority: 'https://login.microsoftonline.com/b8e2940a-1c1f-4f9f-a051-ba699645f645',
        redirectUri: window.location.origin
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
      }
    },
    guardConfig: { interactionType: InteractionType.Redirect },
    interceptorConfig: {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map<string, string[]>(Object.entries({
        'http://localhost:4200/api': ['api://754db438-6784-41aa-995f-a7c6eccbaf0a/access_as_user'],
        'http://localhost:7071/api': ['api://754db438-6784-41aa-995f-a7c6eccbaf0a/access_as_user'],
        'https://phonetest.texasjustice.io/api': ['api://754db438-6784-41aa-995f-a7c6eccbaf0a/access_as_user']
      })),
    }
  },
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
