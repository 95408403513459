<h1 class="callNoteTitle" mat-dialog-title>Add Note</h1>
<div mat-dialog-content>
  <mat-form-field class="callNote" appearance="fill">
    <mat-label>Note</mat-label>
    <textarea matInput [(ngModel)]="recordNotes" rows="5"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onYesClick()" cdkFocusInitial>Save</button>
</div>
