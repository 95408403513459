declare var Twilio: any;
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { phoneRecordTest } from '../phoneRecordTest';
import { Test } from '../test';
import { PreviousTestsDialogComponent } from '../previous-tests-dialog/previous-tests-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';

@Component({
  selector: 'app-dial-pad',
  templateUrl: './dial-pad.component.html',
  styleUrls: ['./dial-pad.component.scss']
})
export class DialPadComponent {
  public twilioNumbers: string[] = ["(512) 785-8640", "(512) 785-2510"];
  public phoneNumber: string = "";
  public selectedNumber: string = "";
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public phoneRecords: phoneRecordTest[];
  public testLoaded: boolean;
  public tests: Test[] = [];

  constructor(private http: HttpClient, public dialog: MatDialog, private appService: AppService) {
    this.http.get(`${environment.api}GetPhoneNumbers`).subscribe(records => {
      console.log(records);
      this.phoneRecords = (records as phoneRecordTest[]);
    });

    // this.http.get('http://localhost:7071/api/GetToken').subscribe(token => {
    //   Twilio.Device.setup(token);
    // });
  }

  makeCall() {
    if (this.phoneNumber && this.selectedNumber) {
      Twilio.Device.connect({ phoneNumber: this.phoneNumber, twilioNumber: this.selectedNumber });
    }
  }

  appendNumber(num: number | string) {
    this.phoneNumber += num;
  }

  clearNumber() {
    this.phoneNumber = '';
  }

  startTest() {
    let userId: string = 'ad482067-42e5-466a-9617-8f7bc12d0ac0';

    if(this.appService.user)
      userId = this.appService.user.homeAccountId;

    this.http.get(`${environment.api}SaveTest/${userId}`).subscribe((records: phoneRecordTest[]) => {
      this.phoneRecords = records;
      this.testLoaded = true;
    });
  }

  continueTest() {
    this.http.get(`${environment.api}GetTests`).subscribe((tests: Test[]) => {
      console.log(tests);
      const dialogRef = this.dialog.open(PreviousTestsDialogComponent, {
        width: '500px',
        data: tests
      });

      dialogRef.afterClosed().subscribe((records) => {
        if (records) {
          this.phoneRecords = records;
          this.testLoaded = true;
        }
      });
    });
  }
}

