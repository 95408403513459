<h2 mat-dialog-title>Select a Test</h2>
<div mat-dialog-content>
  <table mat-table [dataSource]="data" class="mat-elevation-z8">

    <ng-container matColumnDef="testStartDate">
      <th mat-header-cell *matHeaderCellDef> Start Date </th>
      <td mat-cell *matCellDef="let element"> {{element.testStartDate | date:'short' }} </td>
    </ng-container>

    <ng-container matColumnDef="percentComplete">
      <th mat-header-cell *matHeaderCellDef> Percent Complete </th>
      <td mat-cell *matCellDef="let element"> {{element.percentComplete}}% </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectTest(row)"></tr>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
</div>
