import { Injectable } from '@angular/core';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { isEmpty } from 'lodash';

import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private msalService: MsalService) { }

  async init(router: Router): Promise<void> {
    const u = this.user;
    console.log(u);
    if (!isEmpty(u)) {
      const authResult = await this.getToken(u);
      this.saveUser(u);
      this.loadData();
    }
  }

  public get user(): AccountInfo {
    return (this.msalService.instance.getAllAccounts() || [])[0];
  }

  private saveUser(user: AccountInfo): void {
  }

  private loadData(): void {
  }

  private async getToken(user: AccountInfo): Promise<AuthenticationResult> {
    const scopes = environment.msal.interceptorConfig.protectedResourceMap.get(environment.api) || [];
    return this.msalService.acquireTokenSilent({ account: user,scopes: ['api://754db438-6784-41aa-995f-a7c6eccbaf0a/access_as_user'], redirectUri: `${window.location.origin}/blank.html` })
      .toPromise()
      .catch(err => {
        console.error(err);
        this.requestLogin();
        return {} as AuthenticationResult;
      });
  }

  public requestLogin(): void {
    this.msalService.loginRedirect();
  }
}
