import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Device from '@twilio/voice-sdk/es5/twilio/device';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare var Twilio: any;

@Injectable({
  providedIn: 'root'
})
export class TwilioService {
  private device: Device;

  constructor(private http: HttpClient) {

  }

  private getToken(fromPhoneType: string): Observable<string> {
    return this.http.get(`${environment.api}GetTwilioToken/${fromPhoneType}`).pipe(
      catchError(err => {
        console.error(err);
        throw err;
      }),
      map(response => (response as any).token)
   );
  }

  makeCall(fromPhoneType: string, toPhoneNumber: string) {
   this.getToken(fromPhoneType).subscribe(async token => {
      const Device = require('@twilio/voice-sdk').Device;
     this.device = new Device(token);

      let call = await this.device.connect({
        params: {
          To: toPhoneNumber
        }
      });

      console.log(call);
   });
  }

  hangUp() {
    this.device.disconnectAll();
  }
}
